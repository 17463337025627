/**
 * Home Controller - controls everything related to the homepage only.
 */
import Rellax from 'rellax'

export default class Home {
    constructor() {
        this.config = {
            parallaxClassNames: {
                slow: '.parallax-item-slow',
                medium: '.parallax-item-medium',
                fast: '.parallax-item-fast',
            }
        }
        this.rellax = null
    }

    initParallax = () => {
        const rellax = new Rellax(this.config.parallaxClassNames.slow)
        this.rellax = rellax
    }

    init = () => {
        this.initParallax()
    }
}